import http from './index';

const API_SIGNATURE = {
  async getSigntature(param) {
    const request = await http.get(`user/signature/${param}`);
    if (request.status === 200) {
      return request?.data?.signature;
    }
    return false;
  },
};

export default API_SIGNATURE;
