<template>
  <div class="content-div-hs">
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="true"
      :paginate-elements-by-height="1100"
      filename="HISTORIAL OFTALMOLOGÍA"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      ref="html2Pdf"
    >
      <pdf-content
        slot="pdf-content"
        :appointment = "appointmentId"
        :identification = "patientGeneralInfo"
        :refractionSection= "refraction"
        :signosVitales= "vitalSign"
        :appointmentDate="appointmentDate"
      />
    </vue-html2pdf>
    <el-container>
      <el-main>
        <div class="options">
          <el-button
            type="primary"
            icon="el-icon-printer"
            size="mini"
            @click="downloadPdfOld"
          >IMPRIMIR
          </el-button>
        </div>
        <div class="options" style="margin-top: 10px;">
          <el-button
            type="primary"
            icon="el-icon-printer"
            size="mini"
            @click="downloadPdf"
          >IMPRIMIR FORMATO FUNDACIÓN
          </el-button>
        </div>
        <div class="content-date">
          <div
            style="background-color: #6A41BD;
            width: 95px;
            height: 75px;
            display: flex;
            justify-content: center;
            border-radius: 5px;
            "
          >
            <img
              src="../../assets/login-bg.png"
              alt="logo Codet"
              style="width: 75px; height: 75px"
            >
        </div>
          <div class="medical-note">
            <p>{{ appointmentDate === null ? '' : $dateString(appointmentDate) }}</p>
          </div>
        </div>
        <div class="content-header">
          <div class="content-header-child">
            <h4>Datos Generales del Paciente</h4>
            <table style="width: 100%;">
              <tbody>
                <tr>
                  <td style="width: 30%">
                    <table style="width: 100%" class="table-style">
                      <tbody>
                        <tr>
                          <td style="width:50%; font-weight: bold">Nombre(s)</td>
                          <td style="width:50%;">{{patientGeneralInfo.name}}</td>
                        </tr>
                        <tr>
                          <td style="width:50%; font-weight: bold">Sexo</td>
                          <td style="width:50%;">{{patientGeneralInfo.gender}}</td>
                        </tr>
                        <tr>
                          <td style="width:50%; font-weight: bold">Edad</td>
                          <td style="width:50%;">{{patientGeneralInfo.birthdate || 'Sin registrar'}}
                            ({{ $ageAsWords(patientGeneralInfo.birthdate) }})</td>
                        </tr>
                        <tr>
                          <td style="width:50%; font-weight: bold">No.Afiliación</td>
                          <td style="width:50%;">{{patientGeneralInfo.afiliacion}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="content-header">
          <div class="content-header-child">
            <h4>Signos vitales e IMC</h4>
            <table style="width: 100%;">
              <tbody>
                <tr>
                  <td style="width: 30%">
                    <table style="width: 100%" class="table-style">
                      <tbody>
                        <tr>
                          <td style="width:50%; font-weight: bold">Peso</td>
                          <td style="width:50%;">{{vitalSign.weight}} kg.</td>
                        </tr>
                        <tr>
                          <td style="width:50%; font-weight: bold">Estatura</td>
                          <td style="width:50%;">{{vitalSign.size}} mts.</td>
                        </tr>
                        <tr>
                          <td style="width:50%; font-weight: bold">F.card</td>
                          <td style="width:50%;">{{vitalSign.hearRate}} por minuto</td>
                        </tr>
                        <tr>
                          <td style="width:50%; font-weight: bold">F.resp</td>
                          <td style="width:50%;">{{ vitalSign.respiratoryRate }} por minuto</td>
                        </tr>
                        <tr>
                          <td style="width:50%; font-weight: bold">Presión Arterial</td>
                          <td style="width:50%;">{{vitalSign.bloodPressure}}</td>
                        </tr>
                        <tr>
                          <td style="width:50%; font-weight: bold">Temperatura</td>
                          <td style="width:50%;">{{ vitalSign.temperature }} ºC</td>
                        </tr>
                        <tr>
                          <td style="width:50%; font-weight: bold">IMC</td>
                          <td style="width:50%;">{{vitalSign.IMC}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="content-header">
          <div class="content-header-child">
            <h4>Motivo del Encuentro</h4>
            <table style="width: 100%" class="table-style">
              <tbody>
                <tr>
                  <td id="tableMotive">
                    <span style="white-space: pre-wrap;">
                    <!-- {{reasonConsult}} -->
                    <p v-html="reasonConsult" />
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="content-header">
          <div class="content-header-child">
            <h4>Refracción</h4>
            <div style="width: 98%;" class="optometria">
              <el-table
              :data="refraction"
              type="index"
              :index="indexMethod"
              style="width: 99%"
              >
                <el-table-column
                  label=" "
                  prop="name"
                />
                <el-table-column
                  label="Ojo Derecho"
                  prop="OD"
                />
                <el-table-column
                  label="Ojo Izquierdo"
                  prop="OI"
                />
              </el-table>
            </div>
          </div>
        </div>
        <div class="content-header">
          <div class="content-header-child">
            <h4>Segmento Anterior</h4>
            <el-table
              :data="anteriorSegment"
              style="width: 98%;"
              class="optometria"
            >
              <el-table-column
                height=100
                prop="inf"
                label=" "
              />
              <el-table-column
                height=100
                prop="OD"
                label="Ojo Derecho"
              />
              <el-table-column
                height=100
                prop="OI"
                label="Ojo Izquierdo"
              />
            </el-table>
            <h4>Segmento Posterior</h4>
            <el-table
              :data="posteriorSegment"
              style="width: 98%;"
              class="optometria"
            >
              <el-table-column
                prop="inf"
                label=" "
              />
              <el-table-column
                prop="OD"
                label="Ojo Derecho"
              />
              <el-table-column
                prop="OI"
                label="Ojo Izquierdo"
              />
            </el-table>
          </div>
        </div>
        <div class="content-header">
          <div class="content-header-child">
            <h4>Interpretación de estudios</h4>
            <el-table
              :data="iof"
              style=
              "
                width: 98%;
                margin-bottom: 15px;
              "
            >
              <el-table-column
                style="width:70%"
                prop="descriptionOD"
                label="Ojo Derecho"
              />
              <el-table-column
                style="width:10%"
                prop="descriptionOI"
                label="Ojo Izquierdo"
              />
            </el-table>
          </div>
        </div>
        <div class="content-header">
          <div class="content-header-child">
            <h4>Diagnóstico</h4>
            <table style="width: 100%" class="table-style">
              <tbody>
                <tr style="text-align:left;font-size:14px;">
                  <th>Ojo Derecho</th>
                  <th>Ojo Izquierdo</th>
                </tr>
                <tr>
                  <td style="width:97%;display: flex;">
                    <div>
                      <span style="white-space: pre-wrap;" id="eyesComplements">
                        <p style="margin-top:8px;" v-html="Diagnostic.descriptionOD" />
                      </span>
                    </div>
                  </td>
                  <td style="width:50%;">
                    <div>
                      <span style="white-space: pre-wrap;" id="eyesComplements">
                        <p style="margin-top:8px;" v-html="Diagnostic.descriptionOI" />
                      </span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="content-header">
          <div class="content-header-child">
            <h4>Plan</h4>
            <table style="width:100%;padding:0px 10px">
              <tbody>
                <tr style="text-align:left;">
                  <th>Ojo Derecho</th>
                  <th>Ojo Izquierdo</th>
                </tr>
                <tr>
                  <td style="width:97%;display: flex;">
                    <div>
                      <span style="white-space: pre-wrap;" id="eyesComplements">
                        <p style="margin-top:8px;" v-html="plan.descriptionOD" />
                      </span>
                    </div>
                  </td>
                  <td style="width:50%;">
                    <div>
                      <span style="white-space: pre-wrap;" id="eyesComplements">
                        <p style="margin-top:8px;" v-html="plan.descriptionOI" />
                      </span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="content-header">
          <div class="content-header-child">
            <h4>Plan quirúrgico</h4>
            <table style="width:100%;padding:0px 10px">
              <tbody>
                <tr style="text-align:left;">
                  <th>Ojo Derecho</th>
                  <th>Ojo Izquierdo</th>
                </tr>
                <tr>
                  <td style="width:97%;display: flex;">
                    <div>
                      <span style="white-space: pre-wrap;" id="eyesComplements">
                        <p style="margin-top:8px;" v-html="surgicalPlan[0].descriptionOD" />
                      </span>
                    </div>
                  </td>
                  <td style="width:50%;">
                    <div>
                      <span style="white-space: pre-wrap;" id="eyesComplements">
                        <p style="margin-top:8px;" v-html="surgicalPlan[0].descriptionOI" />
                      </span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="content-header">
          <div class="content-header-child">
            <h4>Comentarios</h4>
            <p>{{commments}}</p>
          </div>
        </div>
        <div
          class="image-firma"
          v-if="signatureUser"
          style="display:flex;justify-content:center;"
        >
          <img alt="Firma" :src="signatureUser" style="width:190px;margin-bottom:-30px;" >
        </div>
        <div class="firma" style="text-align: center;">
          ____________________________________________
          <div class="info-evaluator" style="margin:8px 0px 15px 0px">
            <strong> {{ creatorName }} </strong><br><br>
            <div v-if="cedula.length > 0">
              <strong>Cédula profesional: </strong>
              <label>
                {{ cedula.join(', ') }}
              </label>
            </div>
          </div>
        </div>
        <div class="content-header" style="border-style: none; text-align: center;">
          Fundación +luz | Av. Eusebio Kino #10712, Tijuana, 22010, Tijuana, Baja California
          <br>
          Teléfono
          (664) 682 8370
        </div>
      </el-main>
    </el-container>
    <el-dialog
      :visible.sync="dialogImprimir"
      width="80%"
      :close-on-click-modal="false"
      :destroy-on-close="true"
    >
      <div style="padding: 45px 0px 0 0px;" v-if="pdfUrl">
        <iframe
          type="application/pdf"
          width="100%"
          :src="pdfUrl"
          height="720px"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import VueHtml2pdf from 'vue-html2pdf';
import PdfContent from './pfdformatPrint';
import PdftPrint from '../../API/pdfPrint';
import API_SIGNATURE from '../../API/signature';

export default {
  name: 'historyOftaComponent',
  data() {
    return {
      pdfUrl: null,
      dialogImprimir: false,
      element: null,
      cedula: [],
      signatureUser: null,
      vitalSign: {
        weight: 'N/A',
        size: 'N/A',
        hearRate: 'N/A',
        respiratoryRate: 'N/A',
        bloodPressure: 'N/A',
        temperature: 'N/A',
        IMC: 'N/A',
      },
      refraction:
      [
        {
          name: 'AVSC',
          OD: '',
          OI: '',
        },
        {
          name: 'Esfera',
          OD: '',
          OI: '',
        },
        {
          name: 'Cilindro',
          OD: '',
          OI: '',
        },
        {
          name: 'Eje',
          OD: '',
          OI: '',
        },
        {
          name: 'Adición',
          OD: '',
          OI: '',
        },
        {
          name: 'AVCC',
          OD: '',
          OI: '',
        },
        {
          name: 'Presión ocular',
          OD: '',
          OI: '',
        },
      ],
      anteriorSegment:
      [
        {
          inf: 'Globo Ocular',
          OD: '',
          OI: '',
        },
        {
          inf: 'Parpados',
          OD: '',
          OI: '',
        },
        {
          inf: 'Anexos',
          OD: '',
          OI: '',
        },
        {
          inf: 'Motilidad Ocular',
          OD: '',
          OI: '',
        },
        {
          inf: 'Conjuntiva',
          OD: '',
          OI: '',
        },
        {
          inf: 'Esclera',
          OD: '',
          OI: '',
        },
        {
          inf: 'Córnea',
          OD: '',
          OI: '',
        },
        {
          inf: 'CA',
          OD: '',
          OI: '',
        },
        {
          inf: 'Iris',
          OD: '',
          OI: '',
        },
        {
          inf: 'Goníoscopía',
          OD: '',
          OI: '',
        },
        {
          inf: 'Cristalino',
          OD: '',
          OI: '',
        },
      ],
      posteriorSegment:
      [
        {
          inf: 'Viltreo',
          OD: '',
          OI: '',
        },
        {
          inf: 'Nervio',
          OD: '',
          OI: '',
        },
        {
          inf: 'Rel. C/D',
          OD: '',
          OI: '',
        },
        {
          inf: 'Mácula',
          OD: '',
          OI: '',
        },
        {
          inf: 'Vasos',
          OD: '',
          OI: '',
        },
        {
          inf: 'Periferia',
          OD: '',
          OI: '',
        },
        {
          inf: 'Retina General',
          OD: '',
          OI: '',
        },
      ],
      iof:
      [
        {
          descriptionOD: '',
          descriptionOI: '',
        },
      ],
      Diagnostic: {
        descriptionOD: '',
        descriptionOI: '',
      },
      plan: {
        descriptionOD: '',
        descriptionOI: '',
      },
      surgicalPlan: [
        {
          descriptionOD: '',
          descriptionOI: '',
        },
      ],
      commments: '',
      isFullProfile: {
        type: Boolean,
        default: true,
      },
      patientGeneralInfo: {
        name: 'N/A',
        gender: 'N/A',
        age: 'N/A',
        afiliacion: 'N/A',
      },
      appointmentDate: null,
      appointmentId: '',
      patientId: '',
      creatorId: '',
      creatorName: '',
      reasonConsult: '',
      notasMedicas: false,
      historialQuirurgico: false,
      diagnostico: false,
    };
  },
  components: {
    VueHtml2pdf,
    PdfContent,
  },
  computed: {
    ...mapFields(['controlValue']),
  },
  methods: {
    async downloadPdf() {
      try {
        this.$loading();
        const data = {
          pacientes: this.patientGeneralInfo,
          vitalSign: this.vitalSign,
          refraction: this.refraction,
          anteriorSegment: this.anteriorSegment,
          posteriorSegment: this.posteriorSegment,
          iof: this.iof,
          Diagnostic: this.Diagnostic,
          plan: this.plan,
          comments: this.commments,
          reasonConsult: this.reasonConsult,
          medico: {
            name: this.creatorName,
            cedula: this.cedula,
            signature: this.signatureUser,
          },
        };
        const pdf = await PdftPrint.getPrintOftalmologia(
          data,
          this.appointmentDate,
        );
        this.dialogImprimir = true;
        this.pdfUrl = pdf;
        this.$loading().close();
      } catch (error) {
        this.$loading().close();
      }
    },
    downloadPdfOld() {
      this.$refs.html2Pdf.generatePdf();
    },
    async getCedula(creatorId) {
      try {
        const request = await this.axios.get(`patient/cedula/${creatorId}`);
        if (request) {
          request.data.forEach((element) => {
            this.cedula.push(element.number);
          });
        }
      } catch (error) {
        // console.log(error);
      }
    },
    async getSignatureUser(creatorId) {
      try {
        const request = await API_SIGNATURE.getSigntature(creatorId);
        if (request) {
          this.signatureUser = request;
        }
      } catch (error) {
        // console.log(error);
      }
    },
    indexMethod(index) {
      return index * 2;
    },
    async getDataPatientGeneralInfo() {
      try {
        const response = await this.axios.get(`patient/ophtha/patientGeneralInfoPatient/${this.patientId}`);
        if (response.data) {
          this.data = response.data;
          // asignando data
          this.patientGeneralInfo.name = `${this.data[0].firstName} ${this.data[0].lastName}`;
          this.patientGeneralInfo.gender = this.data[0].gender || 'Sin especificar';
          this.patientGeneralInfo.afiliacion = this.data[0].affiliation;
          this.patientGeneralInfo.birthdate = this.data[0].birthdate;
          this.patientGeneralInfo.afiliacion = this.data[0].affiliation || 'Sin especificar';

          const response2 = await this.axios.get(`patient/ophtha/fechaDocumento/${this.appointmentId}`);
          this.appointmentDate = response2.data[0].date;
        }
      } catch (error) {
        this.$errorNotify(error);
      }
    },
    async getDataOptometria() {
      try {
        const response = await this.axios.get(`patient/opto/signalvitalv2/${this.patientId}/${this.appointmentId}`);
        if (response.data.length > 0) {
          // datos de Signos vitales
          const vital = response.data[0];
          this.vitalSign.weight = vital.weight;
          this.vitalSign.size = vital.size;
          this.vitalSign.hearRate = vital.heartRate;
          this.vitalSign.respiratoryRate = vital.respiratoryRate;
          this.vitalSign.bloodPressure = vital.bloodPressure;
          this.vitalSign.temperature = vital.temperature;
          // Calcular IMC
          const peso = this.vitalSign.weight;
          const altura = this.vitalSign.size ** 2;
          const IMC = peso / altura;
          this.vitalSign.IMC = IMC.toFixed(2);
        }
        const optometricId = await this.axios.get(`patient/opto/getAppointmentSubsecuentev2/${this.patientId}/${this.appointmentId}`);

        if (optometricId.data.length > 0) {
          const idEvaluationOptometric = optometricId.data[0].appointmentSub;
          const { data: response2 } = await this.axios.get(`patient/opto/details/${this.patientId}/${idEvaluationOptometric}`);

          if (response2.eyes.length > 0) {
            // datos de refraccion
            response2.eyes.forEach((element) => {
              if (element.eyeSide === 'RIGHT') {
                this.refraction[0].OD = element?.vaNoCorrection ?? 'Sin alteraciones';
                this.refraction[1].OD = element?.refractionSphere ?? 'Sin alteraciones';
                this.refraction[2].OD = element?.refractionCilinder ?? 'Sin alteraciones';
                this.refraction[3].OD = element?.refractionAxis ?? 'Sin alteraciones';
                this.refraction[4].OD = element?.refractionAddition ?? 'Sin alteraciones';
                this.refraction[5].OD = element?.vaWithCorrection ?? 'Sin alteraciones';
                this.refraction[6].OD = element?.intraocularPressure ?? 'Sin alteraciones';
              } else {
                this.refraction[0].OI = element?.vaNoCorrection ?? 'Sin alteraciones';
                this.refraction[1].OI = element?.refractionSphere ?? 'Sin alteraciones';
                this.refraction[2].OI = element?.refractionCilinder ?? 'Sin alteraciones';
                this.refraction[3].OI = element?.refractionAxis ?? 'Sin alteraciones';
                this.refraction[4].OI = element?.refractionAddition ?? 'Sin alteraciones';
                this.refraction[5].OI = element?.vaWithCorrection ?? 'Sin alteraciones';
                this.refraction[6].OI = element?.intraocularPressure ?? 'Sin alteraciones';
              }
            });
          }
        }
      } catch (error) {
        // this.$errorNotify(error);
      }
    },
    async getdataOftalmologia() {
      try {
        const response = await this.axios.get(`patient/ophtha/details/${this.patientId}/${this.appointmentId}`);
        if (response.data) {
          const { data } = response;

          this.commments = data.comments;
          if (data['creator.id']) {
            const firstName = data['creator.firstName'];
            const lastName = data['creator.lastName'];

            this.creatorName = `${firstName} ${lastName}`;
            this.creatorId = data['creator.id'];

            await this.getCedula(this.creatorId);
            await this.getSignatureUser(this.creatorId);
          }

          // datos de motivo de consulta
          this.reasonConsult = response.data.reason;

          // Datos de segmento anterior
          const anteriorSegment = data.anteriorSegment[0].eyeSide;
          const segAntOD = anteriorSegment === 'RIGHT' ? data.anteriorSegment[0] : data.anteriorSegment[1];
          const segAntOI = anteriorSegment === 'LEFT' ? data.anteriorSegment[0] : data.anteriorSegment[1];

          const propertiesAnt = [
            'ocularGlobule',
            'eyelid',
            'annexes',
            'ocularMotility',
            'conjunctiva',
            'sclera',
            'cornea',
            'anteriorChamber',
            'iris',
            'ginioscopy',
            'lens',
          ];

          const updateSegmentAnt = async (segment, property, index, eyeSide) => {
            // console.log(segment);
            if (segment[`${property}Comments`] != null && segment[property] != null) {
              this.anteriorSegment[index][eyeSide] = `${segment[property]}: ${segment[`${property}Comments`]}`;
            } else {
              this.anteriorSegment[index][eyeSide] = segment[property] ?? 'Sin padecimientos';
            }
          };

          propertiesAnt.forEach((property, index) => {
            updateSegmentAnt(segAntOD, property, index, 'OD');
            updateSegmentAnt(segAntOI, property, index, 'OI');
          });

          // Datos de Segmento Posterior
          const posteriorSegment = data.posteriorSegment[0].eyeSide;
          const segPosOD = posteriorSegment === 'RIGHT' ? data.posteriorSegment[0] : data.posteriorSegment[1];
          const segPosOI = posteriorSegment === 'LEFT' ? data.posteriorSegment[0] : data.posteriorSegment[1];

          const propertiesPos = [
            'vitreo',
            'nerve',
            'cupDisc',
            'macula',
            'vessels',
            'periphery',
            'retina',
          ];

          const updateSegment = async (segment, property, index, eyeSide) => {
            if (segment[`${property}Comments`] != null && segment[property] != null) {
              this.posteriorSegment[index][eyeSide] = `${segment[property]}: ${segment[`${property}Comments`]}`;
            } else {
              this.posteriorSegment[index][eyeSide] = segment[property] ?? 'Sin padecimientos';
            }
          };

          propertiesPos.forEach((property, index) => {
            updateSegment(segPosOD, property, index, 'OD');
            updateSegment(segPosOI, property, index, 'OI');
          });

          // interpretationOfDiagnosis (iof)
          this.iof[0].descriptionOD = response.data.posteriorSegment[0].interpretationOfDiagnosis;
          this.iof[0].descriptionOI = response.data.posteriorSegment[1].interpretationOfDiagnosis;

          this.Diagnostic.descriptionOD = response.data.posteriorSegment[0].diagnosis;
          this.Diagnostic.descriptionOI = response.data.posteriorSegment[1].diagnosis;

          this.plan.descriptionOD = response.data.posteriorSegment[0].plan;
          this.plan.descriptionOI = response.data.posteriorSegment[1].plan;

          this.surgicalPlan[0].descriptionOD = response.data.posteriorSegment[0].surgicalPlan;
          this.surgicalPlan[0].descriptionOI = response.data.posteriorSegment[1].surgicalPlan;
        }
      } catch (error) {
        // this.$errorNotify(error);
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.$emit('dom-rendered');
        this.$loading().close();
      }, 3000);
    });
  },
  async created() {
    this.$loading();
    this.appointmentId = this.$route.params.idappointment;
    this.patientId = this.$route.params.id;
    await this.getdataOftalmologia();
    await this.getDataPatientGeneralInfo();
    await this.getDataOptometria();
  },
};
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300&display=swap');
.content-div-hs{
  margin-top: 10px;
  width: 100%;
  line-height: 12px;
}
.options {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}
.content-header{
  width: 50%;
  margin: auto;
  margin-top: 5px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #FFF;
  border-radius: 10px;
  border: solid 1px #ccc;
}
h4{
  // font-family: "Oswald", sans-serif;
  font-weight: 300;
  font-size: 15px;
  margin-bottom: 5px;
}
.content-header-child{
  margin-left: 15px;
  margin-bottom: 5px;
}
.table-style{
  font-size: 12px;
}
.optometria{
  border: solid 1px #ccc;
  border-radius: 8px;
  position: relative;
  margin-bottom: 5px;
}
.content-date{
  margin: auto;
  width:50%;
  display:flex;
  justify-content: space-between;

  .medical-note{
    font-size: 22px;
    text-align: right;
  }
  .firma{
    margin-top: 340px;
  }
}
</style>
<style lang="scss">
.main-container{
  height: auto !important;
}
</style>
<style lang="scss">
// .el-table th.is-leaf, .el-table td {
//     border-bottom: 1px solid #dbdbdb;
// }
.el-table {
  thead {
    color: #000;
  }
  color: #000;
}
.el-table .cell
{
  word-break: normal;
  line-height: 16px;
  padding-right: 0px;
  margin-right: 5px;
}
#eyesComplements {
  font-size: 10px;
  font-weight: 400;
}
</style>
