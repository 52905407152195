<template>
<div class="div-oftaHistory">
  <br>
  <Title2
    title="HISTORIAL GENERAL DEL PACIENTE"
    label="Datos generales del paciente"
  />
  <history/>
</div>
</template>

<script>
import history from './fortmatpdf';
import Title2 from '../Title2';

export default {
  data() {
    return {
      other: 'dede',
      data: [],
    };
  },
  components: {
    history,
    Title2,
  },
  // async created() {
  //   this.other = this.$route.params.idappointment;
  // },
};
</script>

<style scoped>
.div-oftaHistory{
  min-height: auto;
  margin: auto;
  background-color: #fff;
}
</style>
