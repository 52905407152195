import http from './index';
import fechaString from '../utils/dateString';
import { ageNumber } from '../utils/ageAsWords';

const BASEURI = 'patient/printService';

const printApi = {
  // eslint-disable-next-line consistent-return
  async getPrintOftalmologia(data, date) {
    try {
      const dateFormatted = fechaString(date);
      // eslint-disable-next-line no-param-reassign
      data.pacientes.birthdate = ageNumber(data.birthdate);
      const request = await http.post(`${BASEURI}/oftalmologia`, {
        data,
        date: dateFormatted,
      });
      if (request) {
        return request.data;
      }
    } catch (error) {
      return error;
    }
  },
  // eslint-disable-next-line consistent-return
  async getPrintRecepcion(data, date) {
    try {
      const orderData = await data.citas.sort((a, b) => {
        if (a.time > b.time) return 1;
        return -1;
      });
      const dateFormatted = fechaString(date);
      const request = await http.post(`${BASEURI}/recepcion`, {
        pacientes: orderData,
        tipoAgenda: data.tipoAgenda,
        date: dateFormatted,
      });
      if (request) {
        return request.data;
      }
    } catch (error) {
      return error;
    }
  },
  // eslint-disable-next-line consistent-return
  async getPrintSocioEconomic(data, date) {
    try {
      const dateFormatted = fechaString(date);
      const request = await http.post(`${BASEURI}/trabajosocial`, {
        data,
        date: dateFormatted,
      });
      if (request) {
        return request.data;
      }
    } catch (error) {
      return error;
    }
  },
};

export default printApi;
