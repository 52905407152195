<template>
  <section
    class="pdf-content"
  >
    <el-container
      style="width: 80%; margin: auto;"
    >
      <el-main>
        <div class="content-date">
          <div
            style="background-color: #6A41BD;
            width: 65px;
            height: 55px;
            display: flex;
            justify-content: center;
            border-radius: 5px;
            margin: 5px 5px;
            "
          >
            <img
              src="../../assets/login-bg.png"
              alt="logo Codet"
              style="width: 55px; height: 55px;"
            >
        </div>
        <div class="medical-note">
          <h3> {{ $dateString(appointmentDate) }}</h3>
        </div>
        </div>
        <section class="content-header">
          <div class="content-header-child" style="margin-top: 2px;">
            <strong>Ficha de Identificación</strong>
            <table style="width: 100%;">
              <tbody>
                <tr>
                  <td style="width: 30%">
                    <table style="width: 100%" class="table-style">
                      <tbody>
                        <tr>
                          <td style="width:50%; font-weight: bold">Nombre(s)</td>
                          <td style="width:50%;">{{identification.name}}</td>
                        </tr>
                        <tr>
                          <td style="width:50%; font-weight: bold">Sexo</td>
                          <td style="width:50%;">{{identification.gender}}</td>
                        </tr>
                        <tr>
                          <td style="width:50%; font-weight: bold">Edad</td>
                          <td style="width:50%;">{{identification.birthdate || 'Sin registrar'}}
                            ({{ $ageAsWords(identification.birthdate) }})</td>
                        </tr>
                        <tr>
                          <td style="width:50%; font-weight: bold">No.Afiliación</td>
                          <td style="width:50%;">{{identification.afiliacion}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
        <section class="content-header">
          <div class="content-header-child" style="margin-top: 2px;">
            <strong>Signos vitales</strong>
            <table style="width: 100%;">
              <tbody>
                <tr>
                  <td style="width: 30%">
                    <table style="width: 100%" class="table-style">
                      <tbody>
                        <tr>
                          <td style="width:50%; font-weight: bold">Peso</td>
                          <td style="width:50%;">{{signosVitales.weight}} Kg.</td>
                        </tr>
                        <tr>
                          <td style="width:50%; font-weight: bold">Estatura</td>
                          <td style="width:50%;">{{signosVitales.size}} mts.</td>
                        </tr>
                        <tr>
                          <td style="width:50%; font-weight: bold">F.card</td>
                          <td style="width:50%;">{{signosVitales.hearRate}} por minuto</td>
                        </tr>
                        <tr>
                          <td style="width:50%; font-weight: bold">F.resp</td>
                          <td style="width:50%;">{{ signosVitales.respiratoryRate }} por minuto</td>
                        </tr>
                        <tr>
                          <td style="width:50%; font-weight: bold">Presión Arterial</td>
                          <td style="width:50%;">{{signosVitales.bloodPressure}}</td>
                        </tr>
                        <tr>
                          <td style="width:50%; font-weight: bold">Temperatura</td>
                          <td style="width:50%;">{{ signosVitales.temperature }} ºC</td>
                        </tr>
                        <tr>
                          <td style="width:50%; font-weight: bold">IMC</td>
                          <td style="width:50%;">{{ signosVitales.IMC }} </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
        <section class="content-header">
          <div class="content-header-child" style="margin-top: 2px;">
            <strong>Padecimiento Actual</strong>
            <table style="width: 100%" class="table-style">
              <tbody>
                <tr>
                  <td style="width:50%;" id="tableMotive">
                    <span
                      style="
                        white-space: pre-wrap;
                        font-size: 10px;
                        padding: 0px;
                        margin: 0px;
                      "
                    >
                      <p v-html="reasonConsult" />
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
        <section class="content-header">
          <div style="margin-top: 2px;">
            <strong style="margin-left: 10px;">Refracción</strong>
            <div
              style="width: 95%; font-size: 10px; margin-top: 5px;"
            >
              <table class="tableOptometria" style="margin-bottom: 2px;">
                <tr class="trOptometria">
                  <th/>
                  <th style="padding: 3px; padding-left: 95px">
                    Ojo Derecho
                  </th>
                  <th style="padding: 3px; padding-left: 179px">
                    Ojo Izquierdo
                  </th>
                </tr>
                <tr class="trOptometria">
                  <div class="container-refraction display:flex;flex-direction: row">
                    <td style="padding: 2px; width: 94px;">{{refractionSection[0].name}}</td>
                    <td style="padding: 2px; width: 242px;">{{refractionSection[0].OD}}</td>
                    <td style="padding: 3px;">{{refractionSection[0].OI}}</td>
                  </div>
                  <div class="container-refraction display:flex;flex-direction: row">
                    <td style="padding: 2px; width: 94px;">{{refractionSection[1].name}}</td>
                    <td style="padding: 2px; width: 242px;">{{refractionSection[1].OD}}</td>
                    <td style="padding: 3px;">{{refractionSection[1].OI}}</td>
                  </div>
                  <div class="container-refraction display:flex;flex-direction: row">
                    <td style="padding: 2px; width: 94px;">{{refractionSection[2].name}}</td>
                    <td style="padding: 2px; width: 242px;">{{refractionSection[2].OD}}</td>
                    <td style="padding: 3px;">{{refractionSection[2].OI}}</td>
                  </div>
                  <div class="container-refraction display:flex;flex-direction: row">
                    <td style="padding: 2px; width: 94px;">{{refractionSection[3].name}}</td>
                    <td style="padding: 2px; width: 242px;">{{refractionSection[3].OD}}</td>
                    <td style="padding: 3px;">{{refractionSection[3].OI}}</td>
                  </div>
                  <div class="container-refraction display:flex;flex-direction: row">
                    <td style="padding: 2px; width: 94px;">{{refractionSection[4].name}}</td>
                    <td style="padding: 2px; width: 242px;">{{refractionSection[4].OD}}</td>
                    <td style="padding: 3px;">{{refractionSection[4].OI}}</td>
                  </div>
                  <div class="container-refraction display:flex;flex-direction: row">
                    <td style="padding: 2px; width: 94px;">{{refractionSection[5].name}}</td>
                    <td style="padding: 2px; width: 242px;">{{refractionSection[5].OD}}</td>
                    <td style="padding: 3px;">{{refractionSection[5].OI}}</td>
                  </div>
                  <div class="container-refraction display:flex;flex-direction: row">
                    <td style="padding: 2px; width: 94px;">{{refractionSection[6].name}}</td>
                    <td style="padding: 2px; width: 242px;">{{refractionSection[6].OD}}</td>
                    <td style="padding: 3px;">{{refractionSection[6].OI}}</td>
                  </div>
                </tr>
              </table>
            </div>
          </div>
        </section>
        <div class="html2pdf__page-break" v-if="this.element > 273"/>
        <section class="content-header">
          <div style="margin-left: 10px; margin-top: 2px;">
            <strong style="margin-bottom: 5px">
              Exploración oftalmolóxgica - Segmento Anterior
            </strong>
            <div class="divSegmentoAnterior">
              <table
                class="tablaSegmentoAnterior"
              >
                <tr>
                  <th style="width: 15%"/>
                  <th style="padding-right: 120px; width: 40%">
                    Ojo Derecho
                  </th>
                  <th style="padding-right: 120px; width: 40%">
                    Ojo Izquierdo
                  </th>
                </tr>
                <tr>
                  <td>
                    {{anteriorSegment[0].inf}}
                  </td>
                  <td>
                    {{anteriorSegment[0].OD}}
                  </td>
                  <td>
                    {{anteriorSegment[0].OI}}
                  </td>
                </tr>
                <tr>
                  <td>
                    {{anteriorSegment[1].inf}}
                  </td>
                  <td>
                    {{anteriorSegment[1].OD}}
                  </td>
                  <td>
                    {{anteriorSegment[1].OI}}
                  </td>
                </tr>
                <tr>
                  <td>
                    {{anteriorSegment[2].inf}}
                  </td>
                  <td>
                    {{anteriorSegment[2].OD}}
                  </td>
                  <td>
                    {{anteriorSegment[2].OI}}
                  </td>
                </tr>
                <tr>
                  <td>
                    {{anteriorSegment[3].inf}}
                  </td>
                  <td>
                    {{anteriorSegment[3].OD}}
                  </td>
                  <td>
                    {{anteriorSegment[3].OI}}
                  </td>
                </tr>
                <tr>
                  <td>
                    {{anteriorSegment[4].inf}}
                  </td>
                  <td>
                    {{anteriorSegment[4].OD}}
                  </td>
                  <td>
                    {{anteriorSegment[4].OI}}
                  </td>
                </tr>
                <tr>
                  <td>
                    {{anteriorSegment[5].inf}}
                  </td>
                  <td>
                    {{anteriorSegment[5].OD}}
                  </td>
                  <td>
                    {{anteriorSegment[5].OI}}
                  </td>
                </tr>
                <tr>
                  <td>
                    {{anteriorSegment[6].inf}}
                  </td>
                  <td>
                    {{anteriorSegment[6].OD}}
                  </td>
                  <td>
                    {{anteriorSegment[6].OI}}
                  </td>
                </tr>
                <tr>
                  <td>
                    {{anteriorSegment[7].inf}}
                  </td>
                  <td>
                    {{anteriorSegment[7].OD}}
                  </td>
                  <td>
                    {{anteriorSegment[7].OI}}
                  </td>
                </tr>
                <tr>
                  <td>
                    {{anteriorSegment[8].inf}}
                  </td>
                  <td>
                    {{anteriorSegment[8].OD}}
                  </td>
                  <td>
                    {{anteriorSegment[8].OI}}
                  </td>
                </tr>
                <tr>
                  <td>
                    {{anteriorSegment[9].inf}}
                  </td>
                  <td>
                    {{anteriorSegment[9].OD}}
                  </td>
                  <td>
                    {{anteriorSegment[9].OI}}
                  </td>
                </tr>
                <tr>
                  <td>
                    {{anteriorSegment[10].inf}}
                  </td>
                  <td>
                    {{anteriorSegment[10].OD}}
                  </td>
                  <td>
                    {{anteriorSegment[10].OI}}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </section>
        <div class="html2pdf__page-break"/>
        <section class="content-header" style="margin-top: 40px;">
          <div style="margin-left: 10px; margin-top: 2px">
            <strong>Exploración oftalmológica - Segmento Posterior</strong>
            <table class="tablaSegmentoPosterior">
              <tr>
                <th style="width: 15%"/>
                <th style="padding-right: 120px; width: 40%">Ojo Derecho</th>
                <th style="padding-right: 120px; width: 40%">Ojo Izquierdo</th>
              </tr>
              <tr>
                <td>{{posteriorSegment[0].inf}}</td>
                <td>{{posteriorSegment[0].OD}}</td>
                <td>{{posteriorSegment[0].OI}}</td>
              </tr>
              <tr>
                <td>{{posteriorSegment[1].inf}}</td>
                <td>{{posteriorSegment[1].OD}}</td>
                <td>{{posteriorSegment[1].OI}}</td>
              </tr>
              <tr>
                <td>{{posteriorSegment[2].inf}}</td>
                <td>{{posteriorSegment[2].OD}}</td>
                <td>{{posteriorSegment[2].OI}}</td>
              </tr>
              <tr>
                <td>{{posteriorSegment[3].inf}}</td>
                <td>{{posteriorSegment[3].OD}}</td>
                <td>{{posteriorSegment[3].OI}}</td>
              </tr>
              <tr>
                <td>{{posteriorSegment[4].inf}}</td>
                <td>{{posteriorSegment[4].OD}}</td>
                <td>{{posteriorSegment[4].OI}}</td>
              </tr>
              <tr>
                <td>{{posteriorSegment[5].inf}}</td>
                <td>{{posteriorSegment[5].OD}}</td>
                <td>{{posteriorSegment[5].OI}}</td>
              </tr>
              <tr>
                <td>{{posteriorSegment[6].inf}}</td>
                <td>{{posteriorSegment[6].OD}}</td>
                <td>{{posteriorSegment[6].OI}}</td>
              </tr>
            </table>
          </div>
        </section>
        <div class="content-header">
          <div class="content-header-child">
            <h4>Interpretación de estudios</h4>
            <el-table
              :data="iof"
              style="width: 98%;margin-bottom: 15px;"
            >
              <el-table-column
                style="width:70%"
                prop="descriptionOD"
                label="Ojo Derecho"
              />
              <el-table-column
                style="width:10%"
                prop="descriptionOI"
                label="Ojo Izquierdo"
              />
            </el-table>
          </div>
        </div>
        <div class="content-header">
          <div class="content-header-child" style="margin-top: 2px;">
            <h4>Diagnóstico</h4>
            <table style="width: 100%" class="table-style">
              <tbody>
                <tr style="text-align:left;font-size:10px;">
                  <th>Ojo Derecho</th>
                  <th>Ojo Izquierdo</th>
                </tr>
                <tr>
                  <td style="width:97%;display: flex;">
                      <span style="white-space: pre-wrap;" id="eyesComplements">
                        <p style="margin-top:8px;" v-html="Diagnostic.descriptionOD" />
                      </span>
                  </td>
                  <td style="width:50%;">
                    <div>
                      <span style="white-space: pre-wrap;" id="eyesComplements">
                        <p style="margin-top:8px;" v-html="Diagnostic.descriptionOI" />
                      </span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="content-header">
          <div class="content-header-child" style="margin-top: 2px;">
            <h4>Plan</h4>
            <table style="width: 100%, position: relative;" class="table-style">
              <tbody>
                <tr style="text-align:left;font-size:10px;">
                  <th>Ojo Derecho</th>
                  <th>Ojo Izquierdo</th>
                </tr>
                <tr>
                  <td style="width:97%;display: flex;">
                    <div style="border-top: 1px solid #EBEEF5;">
                      <span style="white-space: pre-wrap;" id="eyesComplements">
                        <p style="margin-top:8px;" v-html="plan.descriptionOD" />
                      </span>
                    </div>
                  </td>
                  <td style="width:50%;">
                    <div>
                      <span style="white-space: pre-wrap;" id="eyesComplements">
                        <p style="margin-top:8px;" v-html="plan.descriptionOI" />
                      </span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <section class="content-header">
          <div class="content-header-child">
            <h4>Plan quirúrgico</h4>
             <table class="tablaSegmentoPosterior">
              <tr>
                <th style="padding-right: 120px; width: 40%">Ojo Derecho</th>
                <th style="padding-right: 120px; width: 40%">Ojo Izquierdo</th>
              </tr>
              <tr>
                <td>{{surgicalPlan[0].descriptionOD}}</td>
                <td>{{surgicalPlan[0].descriptionOI}}</td>
              </tr>
            </table>
          </div>
        </section>
        <div class="content-header">
          <div
            class="content-header-child"
            style="font-size:10px; margin-top: 4px;"
          >
            <strong>Comentarios</strong>
            <p>{{commments}}</p>
          </div>
        </div>
        <div class="firma" style="text-align: center; margin-top: 80px;">
          ____________________________________
          <div class="info-evaluator" style="margin:8px 0px 15px 0px">
            <strong>
              {{creatorName}}
            </strong><br><br>
            <div v-if="cedula.length > 0">
              <strong>Cédula profesional: </strong>
              <label>
                {{ cedula.join(', ') }}
              </label>
            </div>
          </div>
        </div>
        <div
          class="content-header"
          style="border-style: none; text-align: center; font-size: 10px"
        >
          Fundación +luz
          |
          Av. Eusebio Kino #10712,
          Tijuana, 22010, Tijuana, Baja California
          <br>
          Teléfono
          (664) 682 8370
          <!-- <br>
          fundacion@codetvisioninstitute.com
          |
          www.fundacioncodet.org -->
        </div>
      </el-main>
    </el-container>
  </section>
</template>

<script>
export default {
  name: 'historyOftaComponent',
  props: {
    appointment: {
      type: String,
    },
    identification: {
      type: Object,
    },
    signosVitales: {
      type: Object,
    },
    refractionSection: {
      type: Array,
    },
    appointmentDate: {
      type: String,
    },
  },
  data() {
    return {
      cedula: [],
      element: null,
      vitalSign: {
        weight: '',
        size: '',
        hearRate: '',
        respiratoryRate: '',
        bloodPressure: '',
        temperature: '',
        IMC: '',
      },
      anteriorSegment:
      [
        {
          inf: 'Globo Ocular',
          OD: 'Sin Alteraciones',
          OI: 'Sin Alteraciones',
        },
        {
          inf: 'Parpados',
          OD: 'Sin Alteraciones',
          OI: 'Sin Alteraciones',
        },
        {
          inf: 'Anexos',
          OD: 'Sin Alteraciones',
          OI: 'Sin Alteraciones',
        },
        {
          inf: 'Motilidad Ocular',
          OD: 'Sin Alteraciones',
          OI: 'Sin Alteraciones',
        },
        {
          inf: 'Conjuntiva',
          OD: 'Sin Alteraciones',
          OI: 'Sin Alteraciones',
        },
        {
          inf: 'Esclera',
          OD: 'Sin Alteraciones',
          OI: 'Sin Alteraciones',
        },
        {
          inf: 'Córnea',
          OD: 'Sin Alteraciones',
          OI: 'Sin Alteraciones',
        },
        {
          inf: 'CA',
          OD: 'Sin Alteraciones',
          OI: 'Sin Alteraciones',
        },
        {
          inf: 'Iris',
          OD: 'Sin Alteraciones',
          OI: 'Sin Alteraciones',
        },
        {
          inf: 'Gonioscopia',
          OD: 'Sin Alteraciones',
          OI: 'Sin Alteraciones',
        },
        {
          inf: 'Cristalino',
          OD: 'Sin Alteraciones',
          OI: 'Sin Alteraciones',
        },
      ],
      posteriorSegment:
      [
        {
          inf: 'Viltreo',
          OD: 'Sin Alteraciones',
          OI: 'Sin Alteraciones',
        },
        {
          inf: 'Nervio',
          OD: 'Sin Alteraciones',
          OI: 'Sin Alteraciones',
        },
        {
          inf: 'Rel. C/D',
          OD: 'Sin Alteraciones',
          OI: 'Sin Alteraciones',
        },
        {
          inf: 'Mácula',
          OD: 'Sin Alteraciones',
          OI: 'Sin Alteraciones',
        },
        {
          inf: 'Vasos',
          OD: 'Sin Alteraciones',
          OI: 'Sin Alteraciones',
        },
        {
          inf: 'Periferia',
          OD: 'Sin Alteraciones',
          OI: 'Sin Alteraciones',
        },
        {
          inf: 'Retina General',
          OD: 'Sin Alteraciones',
          OI: 'Sin Alteraciones',
        },
      ],
      iof:
      [
        {
          descriptionOD: '',
          descriptionOI: '',
        },
      ],
      Diagnostic: {
        descriptionOD: '',
        descriptionOI: '',
      },
      plan: {
        descriptionOD: '',
        descriptionOI: '',
      },
      surgicalPlan: [
        {
          descriptionOD: '',
          descriptionOI: '',
        },
      ],
      commments: '',
      isFullProfile: {
        type: Boolean,
        default: true,
      },
      patientGeneralInfo: {
        name: 'N/A',
        gender: 'N/A',
        age: 'N/A',
        afiliacion: 'N/A',
      },
      idApointment: '',
      patientId: '',
      creatorId: '',
      creatorName: '',
      reasonConsult: '',
      notasMedicas: false,
      historialQuirurgico: false,
      diagnostico: false,
    };
  },
  methods: {
    async getCedula(creatorId) {
      try {
        const request = await this.axios.get(`patient/cedula/${creatorId}`);
        if (request) {
          request.data.forEach((element) => {
            this.cedula.push(element.number);
          });
        }
      } catch (error) {
        // console.log(error);
      }
    },
    validateControlValue() {
      if (this.controlValue.pdfQuality > 2) {
        this.controlValue.pdfQuality = 2;

        return false;
      }

      if (!this.controlValue.paginateElementsByHeight) {
        this.controlValue.paginateElementsByHeight = 1400;

        return false;
      }

      const paperSizes = 'a4';

      if (!paperSizes.includes(this.controlValue.pdfFormat)) {
        this.controlValue.pdfFormat = 'a4';

        return false;
      }

      if (!this.controlValue.pdfOrientation) {
        this.controlValue.pdfOrientation = 'portrait';

        return false;
      }

      if (!this.controlValue.pdfContentWidth) {
        this.controlValue.pdfContentWidth = '800px';

        return false;
      }

      return true;
    },
    indexMethod(index) {
      return index * 2;
    },
    altura() {
      this.element = document.getElementById('tableMotive').offsetHeight;
    },
    async getdataOftalmologia() {
      try {
        const patientId = this.$route.params.id;
        const { idappointment } = this.$route.params;
        const response = await this.axios.get(`patient/ophtha/details/${patientId}/${idappointment}`);
        if (response.data) {
          let ansOD = null;
          let ansOI = null;
          this.reasonConsult = response.data.reason;
          // OJO IZQUIERDO SEGMENTO ANTERIOR
          const signal = response.data.anteriorSegment[1].eyeSide;
          if (signal === 'RIGHT') {
            const right = response.data.anteriorSegment[1];
            ansOD = right;
          } else if (signal === 'LEFT') {
            const left = response.data.anteriorSegment[0];
            ansOD = left;
          }
          // OcularGlobule
          if (ansOD.ocularGlobuleComments != null && ansOD.ocularGlobule != null) {
            this.anteriorSegment[0].OD = `${ansOD.ocularGlobule}: ${ansOD.ocularGlobuleComments}`;
          } else if (ansOD.ocularGlobule != null) {
            this.anteriorSegment[0].OD = ansOD.ocularGlobule;
          } else {
            this.anteriorSegment[0].OD = ansOD.ocularGlobuleComments;
          }
          // eyelid
          if (ansOD.eyelidComments != null && ansOD.eyelid != null) {
            this.anteriorSegment[1].OD = `${ansOD.eyelid}:  ${ansOD.eyelidComments}`;
          } else if (ansOD.eyelid != null) {
            this.anteriorSegment[1].OD = ansOD.eyelid;
          } else {
            this.anteriorSegment[1].OD = ansOD.eyelidComments;
          }
          // annexes
          if (ansOD.annexesComments != null && ansOD.annexes != null) {
            this.anteriorSegment[2].OD = `${ansOD.annexes}:  ${ansOD.annexesComments}`;
          } else if (ansOD.annexes != null) {
            this.anteriorSegment[2].OD = ansOD.annexes;
          } else {
            this.anteriorSegment[2].OD = ansOD.annexesComments;
          }
          // ocularMotility
          if (ansOD.ocularMotilityComments != null && ansOD.ocularMotility != null) {
            this.anteriorSegment[3].OD = `${ansOD.ocularMotility}:  ${ansOD.ocularMotilityComments}`;
          } else if (ansOD.ocularMotility != null) {
            this.anteriorSegment[3].OD = ansOD.ocularMotility;
          } else {
            this.anteriorSegment[3].OD = ansOD.ocularMotilityComments;
          }
          // conjuntiva
          if (ansOD.conjunctivaComments != null && ansOD.conjunctiva != null) {
            this.anteriorSegment[4].OD = `${ansOD.conjunctiva}:  ${ansOD.conjunctivaComments}`;
          } else if (ansOD.conjunctiva != null) {
            this.anteriorSegment[4].OD = ansOD.conjunctiva;
          } else {
            this.anteriorSegment[4].OD = ansOD.conjunctivaComments;
          }
          // sclera
          if (ansOD.scleraComments != null && ansOD.sclera != null) {
            this.anteriorSegment[5].OD = `${ansOD.sclera}:  ${ansOD.scleraComments}`;
          } else if (ansOD.sclera != null) {
            this.anteriorSegment[5].OD = ansOD.sclera;
          } else {
            this.anteriorSegment[5].OD = ansOD.scleraComments;
          }
          // cornea
          if (ansOD.corneaComments != null && ansOD.cornea != null) {
            this.anteriorSegment[6].OD = `${ansOD.cornea}:  ${ansOD.corneaComments}`;
          } else if (ansOD.cornea != null) {
            this.anteriorSegment[6].OD = ansOD.cornea;
          } else {
            this.anteriorSegment[6].OD = ansOD.corneaComments;
          }
          // anteriorChamber
          if (ansOD.anteriorChamberComments != null && ansOD.anteriorChamber != null) {
            this.anteriorSegment[7].OD = `${ansOD.anteriorChamber}:  ${ansOD.anteriorChamberComments}`;
          } else if (ansOD.anteriorChamber != null) {
            this.anteriorSegment[7].OD = ansOD.anteriorChamber;
          } else {
            this.anteriorSegment[7].OD = ansOD.anteriorChamberComments;
          }
          // iris
          if (ansOD.irisComments != null && ansOD.iris != null) {
            this.anteriorSegment[8].OD = `${ansOD.iris}:  ${ansOD.irisComments}`;
          } else if (ansOD.iris != null) {
            this.anteriorSegment[8].OD = ansOD.iris;
          } else {
            this.anteriorSegment[8].OD = ansOD.irisComments;
          }
          // GENIOSCOPIA
          if (ansOD.ginioscopyComments != null && ansOD.ginioscopy != null) {
            this.anteriorSegment[9].OD = `${ansOD.ginioscopy}: ${ansOD.ginioscopyComments}`;
          } else if (ansOD.ginioscopy != null) {
            this.anteriorSegment[9].OD = ansOD.ginioscopy;
          } else {
            this.anteriorSegment[9].OD = ansOD.ginioscopyComments;
          }
          // CRISTALINO
          if (ansOD.lensComments != null && ansOD.lens != null) {
            this.anteriorSegment[10].OD = `${ansOD.lens}:  ${ansOD.lensComments}`;
          } else if (ansOD.lens != null) {
            this.anteriorSegment[10].OD = ansOD.lens;
          } else {
            this.anteriorSegment[10].OD = ansOD.lensComments;
          }
          const signal2 = response.data.anteriorSegment[0];
          if (signal2.eyeSide === 'LEFT') {
            const left = response.data.anteriorSegment[0];
            ansOI = left;
          } else if (signal2.eyeSide === 'RIGHT') {
            const right = response.data.anteriorSegment[1];
            ansOI = right;
          }
          // OJO IZQUIERDO SEGMENTO ANTERIOR
          // OcularGlobule
          if (ansOI.ocularGlobuleComments != null && ansOI.ocularGlobule != null) {
            this.anteriorSegment[0].OI = `${ansOI.ocularGlobule}: ${ansOI.ocularGlobuleComments}`;
          } else if (ansOI.ocularGlobule != null) {
            this.anteriorSegment[0].OI = ansOI.ocularGlobule;
          } else {
            this.anteriorSegment[0].OI = ansOI.ocularGlobuleComments;
          }
          // eyelid
          if (ansOI.eyelidComments != null && ansOI.eyelid != null) {
            this.anteriorSegment[1].OI = `${ansOI.eyelid}:  ${ansOI.eyelidComments}`;
          } else if (ansOI.eyelid != null) {
            this.anteriorSegment[1].OI = ansOI.eyelid;
          } else {
            this.anteriorSegment[1].OI = ansOI.eyelidComments;
          }
          // annexes
          if (ansOI.annexesComments != null && ansOI.annexes != null) {
            this.anteriorSegment[2].OI = `${ansOI.annexes}:  ${ansOI.annexesComments}`;
          } else if (ansOI.annexes != null) {
            this.anteriorSegment[2].OI = ansOI.annexes;
          } else {
            this.anteriorSegment[2].OI = ansOI.annexesComments;
          }
          // ocularMotility
          if (ansOI.ocularMotilityComments != null && ansOI.ocularMotility != null) {
            this.anteriorSegment[3].OI = `${ansOI.ocularMotility}:  ${ansOI.ocularMotilityComments}`;
          } else if (ansOI.ocularMotility != null) {
            this.anteriorSegment[3].OI = ansOI.ocularMotility;
          } else {
            this.anteriorSegment[3].OI = ansOI.ocularMotilityComments;
          }
          // conjuntiva
          if (ansOI.conjunctivaComments != null && ansOI.conjunctiva != null) {
            this.anteriorSegment[4].OI = `${ansOI.conjunctiva}:  ${ansOI.conjunctivaComments}`;
          } else if (ansOI.conjunctiva != null) {
            this.anteriorSegment[4].OI = ansOI.conjunctiva;
          } else {
            this.anteriorSegment[4].OI = ansOI.conjunctivaComments;
          }
          // sclera
          if (ansOI.scleraComments != null && ansOI.sclera != null) {
            this.anteriorSegment[5].OI = `${ansOI.sclera}:  ${ansOI.scleraComments}`;
          } else if (ansOI.sclera != null) {
            this.anteriorSegment[5].OI = ansOI.sclera;
          } else {
            this.anteriorSegment[5].OI = ansOI.scleraComments;
          }
          // cornea
          if (ansOI.corneaComments != null && ansOI.cornea != null) {
            this.anteriorSegment[6].OI = `${ansOI.cornea}:  ${ansOI.corneaComments}`;
          } else if (ansOI.cornea != null) {
            this.anteriorSegment[6].OI = ansOI.cornea;
          } else {
            this.anteriorSegment[6].OI = ansOI.corneaComments;
          }
          // anteriorChamber
          if (ansOI.anteriorChamberComments != null && ansOI.anteriorChamber != null) {
            this.anteriorSegment[7].OI = `${ansOI.anteriorChamber}:  ${ansOI.anteriorChamberComments}`;
          } else if (ansOI.anteriorChamber != null) {
            this.anteriorSegment[7].OI = ansOI.anteriorChamber;
          } else {
            this.anteriorSegment[7].OI = ansOI.anteriorChamberComments;
          }
          // iris
          if (ansOI.irisComments != null && ansOI.iris != null) {
            this.anteriorSegment[8].OI = `${ansOI.iris}:  ${ansOI.irisComments}`;
          } else if (ansOI.iris != null) {
            this.anteriorSegment[8].OI = ansOI.iris;
          } else {
            this.anteriorSegment[8].OI = ansOI.irisComments;
          }
          // GENIOSCOPIA
          if (ansOI.ginioscopyComments != null && ansOI.ginioscopy != null) {
            this.anteriorSegment[9].OI = `${ansOI.ginioscopy}: ${ansOI.ginioscopyComments}`;
          } else if (ansOI.ginioscopy != null) {
            this.anteriorSegment[9].OI = ansOI.ginioscopy;
          } else {
            this.anteriorSegment[9].OI = ansOI.ginioscopyComments;
          }
          // CRISTALINO
          if (ansOI.lensComments != null && ansOI.lens != null) {
            this.anteriorSegment[10].OI = `${ansOI.lens}:  ${ansOI.lensComments}`;
          } else if (ansOI.lens != null) {
            this.anteriorSegment[10].OI = ansOI.lens;
          } else {
            this.anteriorSegment[10].OI = ansOI.lensComments;
          }

          for (let i = 0; i < this.anteriorSegment.length; i++) {
            if (this.anteriorSegment[i].OD === null) {
              this.anteriorSegment[i].OD = 'Sin alteraciones';
            }
            if (this.anteriorSegment[i].OI === null) {
              this.anteriorSegment[i].OI = 'Sin alteraciones';
            }
          }
          const signal3 = response.data.posteriorSegment[1];
          let segPosOD = null;
          if (signal3.eyeSide === 'RIGHT') {
            const dataOD = response.data.posteriorSegment[1];
            segPosOD = dataOD;
          } else if (signal3.eyeSide === 'LEFT') {
            const dataOI = response.data.posteriorSegment[0];
            segPosOD = dataOI;
          }
          // Datos de Segmento Posterior Ojo DERECHO
          // OJO IZQUIERDO SEGMENTO POSTERIOR
          // vitreo
          if (segPosOD.vitreoComments != null && segPosOD.vitreo != null) {
            this.posteriorSegment[0].OD = `${segPosOD.vitreo}:  ${segPosOD.vitreoComments}`;
          } else if (segPosOD.vitreo != null) {
            this.posteriorSegment[0].OD = segPosOD.vitreo;
          } else {
            this.posteriorSegment[0].OD = segPosOD.vitreoComments;
          }
          // nerveComments
          if (segPosOD.nerveComments != null && segPosOD.nerve != null) {
            this.posteriorSegment[1].OD = `${segPosOD.nerve}:  ${segPosOD.nerveComments}`;
          } else if (segPosOD.nerve != null) {
            this.posteriorSegment[1].OD = segPosOD.nerve;
          } else {
            this.posteriorSegment[1].OD = segPosOD.nerveComments;
          }
          // cupDiscComments
          if (segPosOD.cupDiscComments != null && segPosOD.cupDisc != null) {
            this.posteriorSegment[2].OD = `${segPosOD.cupDisc}:  ${segPosOD.cupDiscComments}`;
          } else if (segPosOD.cupDisc != null) {
            this.posteriorSegment[2].OD = segPosOD.cupDisc;
          } else {
            this.posteriorSegment[2].OD = segPosOD.cupDiscComments;
          }
          // maculaComments
          if (segPosOD.maculaComments != null && segPosOD.macula != null) {
            this.posteriorSegment[3].OD = `${segPosOD.macula}:  ${segPosOD.maculaComments}`;
          } else if (segPosOD.macula != null) {
            this.posteriorSegment[3].OD = segPosOD.macula;
          } else {
            this.posteriorSegment[3].OD = segPosOD.maculaComments;
          }
          // vesselComments
          if (segPosOD.vesselComments != null && segPosOD.vessel != null) {
            this.posteriorSegment[4].OD = `${segPosOD.vessel}:  ${segPosOD.vesselComments}`;
          } else if (segPosOD.vessel != null) {
            this.posteriorSegment[4].OD = segPosOD.vessel;
          } else {
            this.posteriorSegment[4].OD = segPosOD.vesselComments;
          }
          // peripheryComments
          if (segPosOD.peripheryComments != null && segPosOD.peripher != null) {
            this.posteriorSegment[5].OD = `${segPosOD.periphery}:  ${segPosOD.peripheryComments}`;
          } else if (segPosOD.periphery != null) {
            this.posteriorSegment[5].OD = segPosOD.periphery;
          } else {
            this.posteriorSegment[5].OD = segPosOD.peripheryComments;
          }
          // retinaComments
          if (segPosOD.retinaComments != null && segPosOD.retina != null) {
            this.posteriorSegment[6].OD = `${segPosOD.retina}:  ${segPosOD.retinaComments}`;
          } else if (segPosOD.retina != null) {
            this.posteriorSegment[6].OD = segPosOD.retina;
          } else {
            this.posteriorSegment[6].OD = segPosOD.retinaComments;
          }

          // Datos de Segmento Posterior Ojo IZQUIERDO
          const signal4 = response.data.posteriorSegment[0];
          let segPosOI = response.data.posteriorSegment[1];
          if (signal4.eyeSide === 'LEFT') {
            const dataOI = response.data.posteriorSegment[0];
            segPosOI = dataOI;
          } else if (signal4.eyeSide === 'RIGHT') {
            const dataOD = response.data.posteriorSegment[1];
            segPosOI = dataOD;
          }
          // OJO IZQUIERDO SEGMENTO POSTERIOR
          // vitreo
          if (segPosOI.vitreoComments != null && segPosOI.vitreo != null) {
            this.posteriorSegment[0].OI = `${segPosOI.vitreo}:  ${segPosOI.vitreoComments}`;
          } else if (segPosOI.vitreo != null) {
            this.posteriorSegment[0].OI = segPosOI.vitreo;
          } else {
            this.posteriorSegment[0].OI = segPosOI.vitreoComments;
          }
          // nerveComments
          if (segPosOI.nerveComments != null && segPosOI.nerve != null) {
            this.posteriorSegment[1].OI = `${segPosOI.nerve}:  ${segPosOI.nerveComments}`;
          } else if (segPosOI.nerve != null) {
            this.posteriorSegment[1].OI = segPosOI.nerve;
          } else {
            this.posteriorSegment[1].OI = segPosOI.nerveComments;
          }
          // cupDiscComments
          if (segPosOI.cupDiscComments != null && segPosOI.cupDisc != null) {
            this.posteriorSegment[2].OI = `${segPosOI.cupDisc}:  ${segPosOI.cupDiscComments}`;
          } else if (segPosOI.cupDisc != null) {
            this.posteriorSegment[2].OI = segPosOI.cupDisc;
          } else {
            this.posteriorSegment[2].OI = segPosOI.cupDiscComments;
          }
          // maculaComments
          if (segPosOI.maculaComments != null && segPosOI.macula != null) {
            this.posteriorSegment[3].OI = `${segPosOI.macula}:  ${segPosOI.maculaComments}`;
          } else if (segPosOI.macula != null) {
            this.posteriorSegment[3].OI = segPosOI.macula;
          } else {
            this.posteriorSegment[3].OI = segPosOI.maculaComments;
          }
          // vesselComments
          if (segPosOI.vesselComments != null && segPosOI.vessel != null) {
            this.posteriorSegment[4].OI = `${segPosOI.vessel}:  ${segPosOI.vesselComments}`;
          } else if (segPosOI.vessel != null) {
            this.posteriorSegment[4].OI = segPosOI.vessel;
          } else {
            this.posteriorSegment[4].OI = segPosOI.vesselComments;
          }
          // peripheryComments
          if (segPosOI.peripheryComments != null && segPosOI.peripher != null) {
            this.posteriorSegment[5].OI = `${segPosOI.periphery}:  ${segPosOI.peripheryComments}`;
          } else if (segPosOI.periphery != null) {
            this.posteriorSegment[5].OI = segPosOI.periphery;
          } else {
            this.posteriorSegment[5].OI = segPosOI.peripheryComments;
          }
          // retinaComments
          if (segPosOI.retinaComments != null && segPosOI.retina != null) {
            this.posteriorSegment[6].OI = `${segPosOI.retina}:  ${segPosOI.retinaComments}`;
          } else if (segPosOI.retina != null) {
            this.posteriorSegment[6].OI = segPosOI.retina;
          } else {
            this.posteriorSegment[6].OI = segPosOI.retinaComments;
          }

          for (let i = 0; i < this.posteriorSegment.length; i++) {
            if (this.posteriorSegment[i].OD === null) {
              this.posteriorSegment[i].OD = 'Sin alteraciones';
            }
            if (this.posteriorSegment[i].OI === null) {
              this.posteriorSegment[i].OI = 'Sin alteraciones';
            }
          }
          // interpretationOfDiagnosis (iof)
          this.iof[0].descriptionOD = response.data.posteriorSegment[0].interpretationOfDiagnosis;
          this.iof[0].descriptionOI = response.data.posteriorSegment[1].interpretationOfDiagnosis;
          this.Diagnostic.descriptionOD = response.data.posteriorSegment[0].diagnosis;
          this.Diagnostic.descriptionOI = response.data.posteriorSegment[1].diagnosis;

          this.plan.descriptionOD = response.data.posteriorSegment[0].plan;
          this.plan.descriptionOI = response.data.posteriorSegment[1].plan;

          this.surgicalPlan[0].descriptionOD = response.data.posteriorSegment[0].surgicalPlan;
          this.surgicalPlan[0].descriptionOI = response.data.posteriorSegment[1].surgicalPlan;

          this.commments = response.data.comments;
          this.creator(response.data['creator.id']);
          this.getCedula(this.creatorId);
        }
      } catch (error) {
        this.$errorNotify(error);
      }
    },
    async creator(creator) {
      const response = await this.axios.get(`patient/ophtha/creatorInfo/${creator}`);
      if (response) {
        const { firstName } = response.data[0];
        const { lastName } = response.data[0];
        this.creatorName = `${firstName} ${lastName}`;
      }
    },
    async getAppointments() {
      try {
        const response = await this.axios.get(`patient/appointment/md/history/${this.patientId}`);
        const { data } = response;
        if (data) {
          this.tableData = data;
        }
      } catch (error) {
        this.$errorNotify(error);
      }
    },
  },
  async created() {
    this.patientId = this.$route.params.id;
    await this.getdataOftalmologia();
    this.altura();
  },
};
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300&display=swap');
.pdf-content{
  width: 100%;
  line-height: 12px;
}
.content-header{
  width: 100%;
  margin: auto;
  margin-top: 8px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #FFF;
  border-radius: 10px;
  border: solid 1px rgb(100, 100, 100)
}
label{
  font-weight: 300;
  font-size: 12px;
  margin-bottom: 2px;
  padding-top: 5px;
}
h4{
  font-weight: 300;
  font-size: 12px;
  margin-bottom: 5px;
}
p {
  margin: 0px;
}
.content-header-child{
  margin-top: 0px;
  margin-left: 15px;
}
.table-style{
  font-size: 10px;
}
.optometria{
  color: #000000;
  background: #fff;
  border: solid 1px rgb(71, 71, 71);
  border-radius: 8px;
  position: relative;
  margin-bottom: 5px;
}
.content-date{
  font-size: 10px;
  width:100%;
  display:flex;
  justify-content:space-between;

  img {
    width: 100px;
    height: 75px;
  }
}
.medical-note{
  p{
    font-size: 10px;
  }
}
.el-table.el-table--fit.el-table--striped.el-table--enable-row-hover.el-table--enable-row-transition
,.el-table.optometria.el-table--fit.el-table--enable-row-hover.el-table--enable-row-transition,
.el-table.el-table--fit.el-table--border.el-table--enable-row-hover.el-table--enable-row-transition,
.el-table.el-table--fit.el-table--enable-row-hover.el-table--enable-row-transition
{
  font-size: 10px;
}
.cell{
  word-break: break-word;
}
.divSegmentoAnterior {
  margin-top: 10px;
  font-size: 10px;
  margin-bottom: 12px;

  .tablaSegmentoAnterior {
    width: 99%;
    border-collapse: collapse;
    word-wrap:break-word;
    table-layout: fixed;
    tr {
      td {
        padding-left: 20px;
      }
      th, td {
      border: 1px solid #f5f5f5;
      text-align: left;
      padding: 5px;
      }
    }
  }
}
.tablaSegmentoPosterior{
  width: 99%;
  border-collapse: collapse;
  margin-top: 10px;
  font-size: 10px;
  word-wrap:break-word;
  table-layout: fixed;
  tr {
    td {
      padding-left: 20px;
    }
    th, td {
      border: .5px solid #f5f5f5;
      text-align: left;
      padding: 5px;
    }
  }
}
strong {
  font-size: 10px;
}
</style>
<style lang="scss">
.el-table th.is-leaf, .el-table td {
    border-bottom: 1px solid #dbdbdb;
}
.el-table {
  margin-top: 5px;
  thead {
    color: #000;
  }
  color: #000;
}
.el-table .cell
{
  word-break: normal;
  line-height: 16px;
  padding-right: 0px;
  margin-right: 5px;
}
.tableOptometria {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  margin-left: 10px;
  font-size: 10px;
  .trOptometria {
    td {
      padding-left: 15px;
    }
  }
}
td {
  font-weight: bold;
}
#eyesComplements {
  font-size: 10px;
  font-weight: 400;
}
</style>
